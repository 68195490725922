<!-- Topbar Start -->
<div class="navbar-custom">
    <div [class]="containerClass">
        <ul class="list-unstyled topnav-menu float-end mb-0">
<!--
            <li class="d-none d-lg-block" ngbDropdown placement="bottom-start">
                <form class="app-search">
                    <div class="app-search-box">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Search..." id="search-dropdown"
                                ngbDropdownToggle>
                            <button class="btn input-group-text" type="submit">
                                <i class="fe-search"></i>
                            </button>
                        </div>
                        <div class="dropdown-lg" id="search-dropdown" ngbDropdownMenu>

                            <div class="dropdown-header noti-title" ngbDropdownItem>
                                <h5 class="text-overflow mb-2">Found 22 results</h5>
                            </div>


                            <a href="javascript:void(0);" class="dropdown-item notify-item"
                                *ngFor="let result of searchResults">
                                <i class="{{result.icon}} me-1"></i>
                                <span>{{result.text}}</span>
                            </a>


                            <div class="dropdown-header noti-title">
                                <h6 class="text-overflow mb-2 text-uppercase">Users</h6>
                            </div>

                            <div class="notification-list">

                                <a href="javascript:void(0);" class="notify-item" ngbDropdownItem
                                    *ngFor="let user of searchUsers">
                                    <div class="d-flex align-items-start">
                                        <img class="d-flex me-2 rounded-circle" [src]="user.profile" [alt]="user.name"
                                            height="32">
                                        <div class="w-100">
                                            <h5 class="m-0 fs-14">{{user.name}}</h5>
                                            <span class="font-12 mb-0">{{user.position}}</span>
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>
                </form>
            </li>

            <li class="dropdown d-inline-block d-lg-none" ngbDropdown autoClose="outside" placement="bottom-end">
                <a class="nav-link dropdown-toggle arrow-none waves-effect waves-light" href="javascript:void(0);"
                    role="button" ngbDropdownToggle>
                    <i class="fe-search noti-icon"></i>
                </a>
                <div class="dropdown-lg dropdown-menu-end p-0" ngbDropdownMenu>
                    <form class="p-3" ngbDropdownItem>
                        <input type="text" class="form-control" placeholder="Search ..."
                            aria-label="Recipient's username">
                    </form>
                </div>
            </li>

            <li class="notification-list topbar-dropdown" ngbDropdown>
                <a class="nav-link dropdown-toggle waves-effect waves-light" href="javascript:void(0)" role="button"
                    ngbDropdownToggle>
                    <i class="fe-bell noti-icon"></i>
                    <span class="badge bg-danger rounded-circle noti-icon-badge">9</span>
                </a>
                <div class="dropdown-menu-end dropdown-lg" ngbDropdownMenu>


                    <div class="noti-title" ngbDropdownItem>
                        <h5 class="m-0">
                            <span class="float-end">
                                <a [routerLink]="['.']" class="text-dark">
                                    <small>Clear All</small>
                                </a>
                            </span>Notification
                        </h5>
                    </div>

                    <ngx-simplebar class="noti-scroll">


                        <a href="javascript:void(0);" class="notify-item" *ngFor="let item of notificationList"
                            [class.active]="item.isActive" ngbDropdownItem>
                            <div class="notify-icon" *ngIf="item.avatar">
                                <img [src]="item.avatar" class="img-fluid rounded-circle" alt="">
                            </div>
                            <div class="notify-icon bg-{{item.bgColor}}" *ngIf="item.icon">
                                <i [class]="item.icon"></i>
                            </div>
                            <p class="notify-details" *ngIf="!item.avatar">{{item.text}}
                                <small class="text-muted">{{item.subText}}</small>
                            </p>
                            <ng-container *ngIf="item.avatar">
                                <p class="notify-details">{{item.text}}</p>
                                <p class="text-muted mb-0 user-msg">
                                    <small>{{item.subText}}</small>
                                </p>
                            </ng-container>
                        </a>

                    </ngx-simplebar>


                    <a href="javascript:void(0);" class="dropdown-item text-center text-primary notify-item notify-all">
                        View all
                        <i class="fe-arrow-right"></i>
                    </a>

                </div>
            </li>
 -->
            <li class="notification-list topbar-dropdown" ngbDropdown>
                <a class="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light" href="javascript:void(0)"
                    role="button" ngbDropdownToggle>
                    <img [src]="loggedInUser?.data?.user?.Empresa?.FileLogo?.url" alt="user-image" class="img-fluid rounded">
                    <span class="pro-user-name ms-1">
                        {{loggedInUser?.data?.user?.Nombre}} <i class="mdi mdi-chevron-down"></i>
                    </span>
                </a>
                <div ngbDropdownMenu class="dropdown-menu-end profile-dropdown ">
                    <!-- item-->
                    <div class="dropdown-header noti-title" ngbDropdownItem>
                        <h6 class="text-overflow m-0">Bienvenid@ !</h6>
                    </div>

                    <ng-container>
                      <a *ngIf="loggedInUser?.data?.user?.Rol?.Descripcion === 'Administrador' || loggedInUser?.data?.user?.Rol?.Descripcion === 'SuperAdmin'"
                      [routerLink]="['/empresas/edit', loggedInUser?.data?.user?.IdEmpresa]" class="notify-item" ngbDropdownItem>
                          <i class="fe-settings me-1"></i>
                          <span>My Empresa</span>
                      </a>

                      <div class="dropdown-divider"></div>
                      <!-- item-->
                      <a [routerLink]="['/auth/logout']" class="notify-item" ngbDropdownItem>
                          <i class="fe-log-out me-1"></i>
                          <span>Salir</span>
                      </a>
                    </ng-container>

                </div>
            </li>

            <li class="dropdown notification-list">
                <a href="javascript:void(0);" class="nav-link right-bar-toggle waves-effect waves-light"
                    (click)="toggleRightSidebar()">
                    <i class="fe-settings noti-icon"></i>
                </a>
            </li>

        </ul>

        <!-- LOGO -->
        <div class="logo-box">
            <a routerLink="/" class="logo logo-light text-center">
                <span class="logo-sm">
                    <img src="assets/images/logo-sm.png" alt="" height="22">
                </span>
                <span class="logo-lg">
                    <img src="assets/images/logo-light-2.png" alt="" height="50">
                </span>
            </a>
            <a routerLink="/" class="logo logo-dark text-center">
                <span class="logo-sm">
                    <img src="assets/images/logo-sm.png" alt="" height="50">
                </span>
                <span class="logo-lg">
                    <img src="assets/images/logo-dark.png" alt="" height="40">
                </span>
            </a>
        </div>

        <ul class="list-unstyled topnav-menu topnav-menu-left mb-0">
            <li *ngIf="layoutType==='vertical'">
                <button class="button-menu-mobile disable-btn waves-effect open-left"
                    (click)="toggleMobileMenu($event)">
                    <i class="fe-menu"></i>
                </button>
            </li>

            <li *ngIf="layoutType==='horizontal'">
                <!-- Mobile menu toggle (Horizontal Layout)-->
                <a class="navbar-toggle nav-link horizontal-toggle" (click)="toggleMobileMenu($event)">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
                <!-- End mobile menu toggle-->
            </li>

            <li *ngIf="layoutType==='vertical'">
                <h4 class="page-title-main">{{pageTitle}}</h4>
            </li>

        </ul>

        <div class="clearfix"></div>
    </div>
</div>
<!-- end Topbar -->
