<div id="wrapper">

    <!-- topbar -->
    <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()" layoutType="vertical"></app-topbar>

    <!-- left side bar-->
    <app-left-sidebar [includeUserProfile]="showSidebarUserInfo"></app-left-sidebar>

    <div class="content-page">
      <div *ngIf="laderService.isLoading | async" class="loader-container">
        <div *ngFor="let variant of variants" class="spinner-grow text-{{variant}} m-2" role="status"></div>
      </div>
        <div class="content">
            <!-- content -->
            <div class="container-fluid" *ngIf="reRender">
                <router-outlet></router-outlet>
            </div>
        </div>
        <!-- footer -->
        <app-footer></app-footer>
    </div>
</div>
<!-- END wrapper -->

<!-- right sidebar -->
<!--
<app-right-sidebar layoutType="vertical" [layoutColor]="layoutColor" [layoutWidth]="layoutWidth"
    [leftbarPosition]="leftbarPosition" [leftbarColor]="leftbarColor" [leftbarSize]="leftbarSize"
    [topbarColor]="topbarColor" [showSidebarUserInfo]="showSidebarUserInfo">
</app-right-sidebar>
-->
