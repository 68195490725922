import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
// services
import { AuthenticationService } from '../service/auth.service';
import { LoaderService } from '../service/loader.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor (private authenticationService: AuthenticationService,
      public loaderService:LoaderService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.loaderService.isLoading.next(true);
        // add authorization header with jwt token if available
        const currentUser = this.authenticationService.currentUser();
        if (currentUser && currentUser.data?.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.data?.token}`
                }
            });
        }
        return next.handle(request).pipe(
          finalize(()=>{
            this.loaderService.isLoading.next(false);
          })
        );
    }
}
