import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../service/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {

           // Verifica si la solicitud debe ser ignorada
            if (request.headers.has('Skip-Interceptor') && request.headers.get('Skip-Interceptor') === 'true') {
              // Clona la solicitud sin el encabezado 'Skip-Interceptor' para evitar efectos secundarios
              const newReq = request.clone({ headers: request.headers.delete('Skip-Interceptor') });
              return next.handle(newReq);
            }

            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                location.reload();
            }

            const error = err.error.message || err.statusText || err.error.error
            return throwError(error);
        }));
    }
}
