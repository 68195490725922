<div class="account-pages my-5">
    <div class="container">

        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-4">
                <div class="text-center" *ngIf="hasLogo">
                    <div class="auth-logo">
                        <a [routerLink]="'/'" class="logo logo-dark ">
                            <span class="logo-lg">
                                <img src="assets/images/logo-dark.png" alt="" height="100">
                            </span>
                        </a>

                        <a [routerLink]="'/'" class="logo logo-light ">
                            <span class="logo-lg">
                                <img src="assets/images/logo-light.png" alt="" height="100">
                            </span>
                        </a>
                    </div>
                    <p class="text-muted mt-2 mb-4"></p> <!-- posible texto -->

                </div>
                <div class="card-login" [class]="cardClass">
                    <div class="card-body p-4">

                        <ng-content></ng-content>

                    </div> <!-- end card-body -->
                </div>

                <ng-content select="[footerLink]"></ng-content>

            </div> <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
<!-- end page -->
