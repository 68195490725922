import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'appcitytransportes';

  // swUpdate para revisar si hay actualizaciones
  constructor(private swUpdate: SwUpdate) {
    if (this.swUpdate.isEnabled) {
      // ver si hay nueva version
      this.swUpdate.versionUpdates.subscribe(event => {
        if (event.type === 'VERSION_READY') {
          // actualizar (S/N)
          if (confirm('Nueva versión disponible. ¿Deseas actualizar?')) {
            // recargar pagina para actualizar
            window.location.reload();
          }
        }
      });
    }
  }
}
